import Navbar from './navbar'
import Footer from './footer'
import Head from 'next/head'
import { useRouter } from 'next/router'

const Layout = ({ children }) => {
  const router = useRouter()
  const isIframePath = router.pathname.indexOf('/iframe/') > -1

  if (isIframePath) {
    return <>{children}</>
  }

  return (
    <>
      <Head>
        <meta
          name="description"
          content="Find the Right Montessori School with Y Montessori's Comprehensive Data. Compare, Evaluate, and Book Tours with Confidence."
        />
        <meta
          name="keywords"
          content="Center-Based Montessori, Home-Based Montessori, AMI Montessori schools, AMS Montessori schools, AMI accreditation, AMS accreditation, great Montessori schools, mixed age, bilingual, bilingual immersion, bilingual program, toddler, primary, nido, elementary, nap time, potty training, half day Montessori, Full day Montessori, find a Montessori school, Montessori school, Montessori preschool, Montessori care center, Montessori daycare, Montessori classroom, Montessori materials, Montessori at home, Y Montessori, authentic Montessori, why choose Montessori, benefits of Montessori, Montessori parents, Montessori children"
        />
      </Head>
      <Navbar />
      <main>
        {/* <div
          style={{ width: '100%', height: pathname === '/' ? 89 : 60 }}
        ></div> */}
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
